<template>
    <div class="container">
        <div class="col-wrap header">
            <div class="bg  bg-animation2"></div>
            <div class="wrap-content">
                <Nav></Nav>
                <div class="content">
                    <h1>JOIN US</h1>
                    <h3>诚挚邀请英才、汇聚每一份智慧谱写爱创明天</h3>
                    <p>
                        We are glad to have like-minded talents participated in itrax to create a wonderful future
                    </p>
                </div>
            </div>
        </div>
        <div class="col-wrap detail">
            <div class="wrap-content body">
                <div class="switch swiper-prev">
                    <i class="iconfont icon-previous"></i>
                    <span>PREV</span>
                </div>
                <div class="content">
                    <swiper :options="swiperOption" ref="swiper" :delete-instance-on-destroy="true" :cleanup-styles-on-destroy="true">
                        <swiper-slide v-for="item in list" :key="item.id">
                            <div class="item">
                                <div class="col-title">
                                    <h1>{{ item.title }}</h1>
                                    <div>Recruitment position</div>
                                </div>
                                <div class="recruitment-desc" v-html="item.content"></div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="switch swiper-next">
                    <i class="iconfont icon-next"></i>
                    <span>NEXT</span>
                </div>
            </div>
            <div class="tools">
                <section>
                    <a href="javascript:;" @click="backHandle">回到招聘列表</a>
                </section>
            </div>
        </div>
        <div class="col-wrap">
            <div class="content">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
// let vm = null;
export default {
    name: "pc-recruitment-detail",
    components: { Nav, Footer },
    data() {
        return {
            swiperOption: {
                loop: true,
                navigation: {
                    nextEl: ".swiper-next",
                    prevEl: ".swiper-prev",
                },
                notNextTick: true,
                initialSlide: 0,
                // on: {
                //     init: function() {
                //         console.warn("init", vm.defaultIdx);

                //         this.slideTo(vm.defaultIdx, 0);
                //     },
                // },
            },
            list: [],
            defaultIdx: 0,
        };
    },
    // created() {
    //     vm = this;
    // },
    mounted() {
        this.loadDetail();
    },
    methods: {
        backHandle() {
            this.$router.back();
        },
        loadDetail() {
            var city = this.$route.query.city;
            this.$showLoading();
            this.$http
                .get(`/api/recruitment/detail?city=${city}`)
                .then((res) => {
                    this.list = res.data;
                    var idx = res.data.findIndex((item) => item.id == this.$route.query.id);
                    this.swiperOption.initialSlide = idx;
                    this.defaultIdx = idx;
                    //console.warn("loaded", idx);

                    setTimeout(() => {
                        this.$refs["swiper"].$swiper.slideTo(idx + 1, 0);
                        this.$forceUpdate();
                    }, 100);
                })
                .finally(() => {
                    this.$hideLoading();
                });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/pc.css");
@import url("../../../common/css/icon.css");

.item {
    text-align: left;
}

.body {
    display: flex;
    align-items: center;
    justify-content: center;
}
.switch {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}
.switch:hover i {
    font-size: 25px;
}
.switch i {
    font-size: 20px;
    margin-bottom: 3px;
    transition: all 0.3s;
}
.switch span {
    font-size: 12px;
}
.switch * {
    color: #333;
}

.detail {
    margin-bottom: 50px;
    background: url("~@/assets/pc/recruitment/bg.jpg") no-repeat center;
    background-size: 100% auto;
}
.detail .tools {
    width: 1280px;
    margin: 80px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.detail .tools section a {
    width: 152px;
    height: 36px;
    border: 1px #ccc solid;
    border-radius: 19px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #333;
}
.detail .tools section a:hover {
    background: #0144b1;
    color: #fff;
}

.header .wrap-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header .wrap-content .content {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.item {
    width: 100%;
}
@media only screen and (max-width: 1440px) {
    .header .bg {
        background: url("~@/assets/pc/recruitment/banner-bg.jpg") no-repeat center bottom;
        height: 500px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .detail .content {
        width: 1000px;
        min-height: 300px;
    }
    .recruitment-desc {
        margin-left: 20px;
        color: #333333;
        font-size: 16px;
        line-height: 30px;
        text-align: left;
        margin: 0 30px;
    }
}

@media only screen and (min-width: 1441px) {
    .header .bg {
        background: url("~@/assets/pc/recruitment/banner-bg.jpg") center bottom;
        height: 760px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .detail .content {
        width: 1280px;
        min-height: 300px;
    }
    .recruitment-desc {
        margin-left: 69px;
        color: #333333;
        font-size: 16px;
        line-height: 30px;
        text-align: left;
    }
}
</style>
